import React, { useContext } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import ThemeContext from "./ThemeContext";

export const ThemedProgressCircle = ({ progress, completed }) => {
  const { primaryColor } = useContext(ThemeContext);
  return <ProgressCircle progress={progress} completed={completed} primaryColor={primaryColor} />;
};

const ProgressCircle = ({ progress, completed, primaryColor }) => {
  const circleStyle = {
    alignItems: "center",
    background: `conic-gradient(${primaryColor} 0%, ${primaryColor} ${completed ? 100 : progress}%, #eaeaea ${completed ? 100 : progress}%, #eaeaea 100%)`,
    borderRadius: "50%",
    display: "flex",
    filter: "drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06))",
    height: "100px",
    justifyContent: "center",
    position: "relative",
    width: "100px",
  };

  const beforeStyle = {
    backgroundColor: "white",
    borderRadius: "50%",
    content: "\"\"",
    filter: "drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.01))",
    height: "80px",
    position: "absolute",
    width: "80px"
  };

  const textStyle = {
    position: "relative",
    textAlign: "center",
    width: "100%",
    zIndex: 1
  };

  return (
    <div className="progress-circle" style={circleStyle}>
      <div className="progress-text header-2" style={textStyle}>
        {completed ? (
          <div className="flex justify-center items-center">
            <CheckIcon
              className="w-12 h-12 text-school"
              aria-label="check"
              role="img"
            />
          </div>
        ) : (
          <>
            {progress}%
            <br />
            <div className="caption-2">to goal</div>
          </>
        )}
      </div>
      <div style={beforeStyle} />
    </div>
  );
};

export default ProgressCircle;
